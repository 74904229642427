












import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class ButtonComponent extends Vue {
  @Prop({default: false}) loading!: boolean
}
