















































































































































import {Component, Vue} from 'vue-property-decorator'
import {AppModule, ProjectModule} from '@/store'
import {RouteNames} from '@/router'
import ButtonComponent from '@/components/buttons/Button.vue'

@Component({
  components: {
    'v-button': ButtonComponent
  }
})
export default class HomeComponent extends Vue {
  public link = ''
  public creatingClip = false
  public creatingClipBtn1 = false
  public creatingClipBtn2 = false
  public creatingClipBtn3 = false
  public calledWarmup = false

  mounted(): void {
    this.link = ''
    if (!this.calledWarmup) {
      AppModule.warmupLambda()
      this.calledWarmup = true
    }
  }

  public async handleClick(): Promise<void> {
    if (!this.link) {
      return
    }
    this.loadingButton('btn-project', true)
    const {clipId} = await this.createNewClip(this.link)
    await this.$router.push({name: RouteNames.CLIP_DETAIL, params: {clipId}})
      .finally(() => this.loadingButton('btn-project', false))
  }

  public async handleSampleVideo(button: string): Promise<void> {
    this.loadingButton(button, true)
    const {clipId} = await this.createNewClip('https://www.youtube.com/watch?v=LVLoc6FrLi0')
    await this.$router.push({name: RouteNames.CLIP_DETAIL, params: {clipId}})
      .finally(() => this.loadingButton(button, false))
  }

  private async createNewClip(href: string): Promise<{ clipId: string }> {
    const {clipId} = await ProjectModule.createNewClip({href})
    return {clipId}
  }

  private loadingButton(button: string, loading: boolean): void {
    this.creatingClip = loading
    if (!button) {
      return
    }
    switch (button) {
      case 'btn-sample-1':
        this.creatingClipBtn1 = loading
        break
      case 'btn-project':
        this.creatingClipBtn2 = loading
        break
      case 'btn-sample-2':
        this.creatingClipBtn3 = loading
        break
    }
  }
}
